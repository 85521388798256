/* eslint-disable import/prefer-default-export */
import React from 'react';

import { Box, Container } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Layout from '../layout';
import { SubCoursesContainer } from './subCourseComponent';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    backgroundColor: '#1D2226',
    color: 'white',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
    color: 'white',
  },
  title: {
    fontSize: 14,
    color: 'white',
  },
  pos: {
    marginBottom: 12,
  },
  parentContainer: {
    marginTop: 20,
  },
});

const Header = ({ title, code }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {code}
        </Typography>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const ParentContainer = ({ course }) => {
  const classes = useStyles();
  return (
    <Box>
      <Layout appBarTitle="Course">
        <Container fixed className={classes.parentContainer}>
          <Header title={course.title} code={course.code} />
          <SubCoursesContainer subCourses={course.subCourses} />
        </Container>
      </Layout>
    </Box>
  );
};
