import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import About from './pages/about';
import { CourseSearch, CourseDetails } from './pages/course';
import { FacultyDetails, FacultySearch } from './pages/faculty';
import { Home } from './pages/home';
import { client } from './services/apollo';

const App = () => (
  <ApolloProvider client={client}>
    <Router>
      <Switch>
        <Route path="/course/:courseCode">
          <CourseDetails />
        </Route>
        <Route path="/course">
          <CourseSearch />
        </Route>
        <Route path="/faculty/:employeeID">
          <FacultyDetails />
        </Route>
        <Route path="/faculty">
          <FacultySearch />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  </ApolloProvider>
);

export default App;
