/* eslint-disable import/prefer-default-export */
import React from 'react';

import { Container, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { whatsAppShareFacultyInfo } from '../../utils';
import Layout from '../layout';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    margin: '0 auto 20px auto',
    [theme.breakpoints.down('sm')]: {
      padding: '2px',
      width: '65%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2px',
      width: '95%',
    },
    backgroundColor: theme.palette.cardbackground.main,
    marginTop: '20px',
    padding: '20px 20px 0 20px',
  },
  title: {
    fontSize: 14,
    color: theme.palette.cardbackground.title,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  content: {
    fontSize: 18,
    display: 'inline-flex',
    color: theme.palette.cardbackground.content,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  cover: {
    width: '10%',
    height: '10%',
    margin: '60px auto 10px auto',
    display: 'block',
    borderRadius: '5px',

    [theme.breakpoints.down('sm')]: {
      width: '25%',
      height: '25%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '30%',
      height: '30%',
    },
  },
  pos: {
    marginBottom: 0,
  },
}));

export const FacultyDetailsContainer = ({ details }) => {
  const classes = useStyles();
  return (
    <Box>
      <Layout appBarTitle="Faculty Details" />
      <img
        className={classes.cover}
        alt={details.name}
        title={details.name}
        src={`https://cdn-faculty-images.vitspot.com/${details.employeeID}.jpg`}
      />
      <Container>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} lg={6}>
                <Typography className={classes.title} gutterBottom>
                  Name
                </Typography>
                <Typography className={classes.content} gutterBottom>
                  {details.name || 'No data'}
                </Typography>
                <Typography className={classes.title} gutterBottom>
                  School
                </Typography>
                <Typography className={classes.content} gutterBottom>
                  {details.school || 'No data'}
                </Typography>
                <Typography className={classes.title} gutterBottom>
                  Designation
                </Typography>
                <Typography className={classes.content} gutterBottom>
                  {details.designation || 'No data'}
                </Typography>
                <Typography className={classes.title} gutterBottom>
                  Venue
                </Typography>
                <Typography className={classes.content} gutterBottom>
                  {details.venue || 'No data'}
                </Typography>
              </Grid>
              {/* grids break here  */}
              <Grid item md={12} lg={6}>
                <Typography className={classes.title} gutterBottom>
                  Intercom
                </Typography>
                <Typography className={classes.content} gutterBottom>
                  {details.intercom || 'No data'}
                </Typography>
                <Typography className={classes.title} gutterBottom>
                  Email
                </Typography>
                <Typography className={classes.content} gutterBottom>
                  {details.email || 'No data'}
                </Typography>
                <Typography className={classes.title} gutterBottom>
                  Employee ID
                </Typography>
                <Typography className={classes.content} gutterBottom>
                  {details.employeeID || 'No data'}
                </Typography>
                <Typography className={classes.title} gutterBottom>
                  Mobile
                </Typography>
                <Typography className={classes.content} gutterBottom>
                  {details.mobile || 'No data'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.root}>
          <CardContent>
            <WhatsAppIcon fontSize="large" />
            <Button
              style={{ float: 'right' }}
              size="medium"
              onClick={() => {
                const redirectURL = whatsAppShareFacultyInfo(details);
                window.open(redirectURL);
              }}
            >
              Share
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};
