import React, { useState } from 'react';

const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
});

const retreiveToken = () => {
  const storedToken = localStorage.getItem('token');
  return storedToken;
};

export const AuthContextProvider = (props) => {
  const tokenData = retreiveToken();
  let initialToken;
  if (tokenData) {
    initialToken = tokenData;
  }
  const [token, setToken] = useState(initialToken);
  const userIsLoggedIn = !!token;

  const loginHandler = (Returnedtoken) => {
    setToken(Returnedtoken);
    localStorage.setItem('token', Returnedtoken);
  };

  const logoutHandler = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  const contextValue = {
    // eslint-disable-next-line object-shorthand
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };
  // eslint-disable-next-line react/destructuring-assignment
  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
