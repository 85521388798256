import React from 'react';

import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';

/** load themes for application */
import App from './App';
import { AuthContextProvider } from './context/auth-context';
import mainTheme from './themes/main.theme';

ReactDOM.render(
  <AuthContextProvider>
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </AuthContextProvider>,

  document.getElementById('root'),
);
