import { createMuiTheme } from '@material-ui/core/styles';

/**
 * Defines the theme which is used by the entire application.
 *
 * To create an alternate theme, create a few file in the /themes directory and import in index.js
 * @referenced https://material-ui.com/customization/theming/
 */
const mainTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#333333',
    },
    secondary: {
      main: '#F89406',
    },
    cardbackground: {
      main: '#1D2226',
      title: '#d6d6d6',
      content: '#ffffff',
      dark: '#000000',
    },
    background: {
      default: '#0A0A0D',
    },
  },
});

export default mainTheme;
