import React from 'react';

import { Box, CardContent, Container, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../components/layout';
import MetaDescriptor from '../../components/MetaDescriptor';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '4rem auto',
  },
  media: {
    width: 295,
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: 180,
      height: 100,
    },
  },
  card: {
    margin: '0.5rem',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
  },
  textoverlay: {
    position: 'absolute',
    bottom: '5rem',
    marginLeft: '1rem',
    color: theme.palette.cardbackground.light,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      bottom: '2.5rem',
    },
  },
  backOverlay: {
    position: 'relative',
    opacity: '0.85',
    backgroundColor: '#EA725B',
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <Box>
      <MetaDescriptor title="Home" description="Home page of vitspot" />
      <Layout appBarTitle="Home" />
      <Container className={classes.root}>
        <Box style={{ marginRight: '-0.5rem' }}>
          <Card
            className={classes.card}
            component="a"
            href="/course"
            style={{
              backgroundImage: 'url(https://cdn.vitspot.com/assets/app/papers.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }}
          >
            {/* change the url to calculator page */}
            <CardActionArea className={classes.backOverlay}>
              <CardContent className={classes.media}>
                <Box>
                  <Typography variant="h5" className={classes.textoverlay}>
                    Resources
                  </Typography>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card
            className={classes.card}
            component="a"
            href="/"
            style={{
              backgroundImage: 'url(https://cdn.vitspot.com/assets/app/calculator.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }}
          >
            <CardActionArea className={classes.backOverlay}>
              <CardContent className={classes.media}>
                <Typography variant="h5" className={classes.textoverlay}>
                  Calculators
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
        <Box>
          <Card
            className={classes.card}
            component="a"
            href="/faculty"
            style={{
              backgroundImage: 'url(https://cdn.vitspot.com/assets/app/teacher-female.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }}
          >
            <CardActionArea className={classes.backOverlay}>
              <CardContent className={classes.media}>
                <Typography variant="h5" className={classes.textoverlay}>
                  Faculty
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card
            className={classes.card}
            component="a"
            href="/about"
            style={{
              backgroundImage: 'url(https://cdn.vitspot.com/assets/app/work.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }}
          >
            <CardActionArea className={classes.backOverlay}>
              <CardContent className={classes.media}>
                <Typography variant="h5" className={classes.textoverlay}>
                  About
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Container>
    </Box>
  );
};

export default Home;
