import React from 'react';

import { useQuery, gql } from '@apollo/client';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { ParentContainer } from '../../components/courseCard';
import { LoadingAnimation } from '../../components/loader';
import MetaDescriptor from '../../components/MetaDescriptor';

const GET_COURSE = gql`
  query courseByCode($courseCode: String!) {
    courseByCode(code: $courseCode) {
      title
      name
      code
      subCourses {
        id
        name
        files {
          id
          name
          uri
          thumbnailUri
        }
      }
    }
  }
`;

const CourseDetails = () => {
  const { courseCode } = useParams();
  const { loading, error, data } = useQuery(GET_COURSE, {
    variables: { courseCode },
  });

  if (loading) return <LoadingAnimation />;
  if (error) return <p>Error :(</p>;
  return (
    <Box>
      <MetaDescriptor title={data.courseByCode.title} description={`${courseCode} - ${data.courseByCode.title}`} />
      <ParentContainer course={data.courseByCode} />
    </Box>
  );
};

export default CourseDetails;
