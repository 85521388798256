import React from 'react';

import { useQuery, gql } from '@apollo/client';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { FacultyDetailsContainer } from '../../components/facultyCard';
import { LoadingAnimation } from '../../components/loader';
import MetaDescriptor from '../../components/MetaDescriptor';

const GET_FACULTY = gql`
  query facultyByEmployeeId($employeeID: String!) {
    facultyByEmployeeId(employeeID: $employeeID) {
      id
      employeeID
      name
      school
      designation
      venue
      email
      mobile
    }
  }
`;

const FacultyDetails = () => {
  const { employeeID } = useParams();
  const { loading, error, data } = useQuery(GET_FACULTY, {
    variables: { employeeID },
  });

  if (loading) return <LoadingAnimation />;
  if (error) return <p>Error :(</p>;

  return (
    <Box>
      <MetaDescriptor title={data.facultyByEmployeeId.name} description={`${data.facultyByEmployeeId.name} - ${data.facultyByEmployeeId.school}`} />
      <FacultyDetailsContainer details={data.facultyByEmployeeId} />
    </Box>
  );
};

export default FacultyDetails;
