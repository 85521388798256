import { useState } from 'react';

import { useQuery, gql } from '@apollo/client';
import { Box, withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';

import Layout from '../../components/layout';
import MetaDescriptor from '../../components/MetaDescriptor';
import mainTheme from '../../themes/main.theme';

const SEARCH_FACULTIES = gql`
  query facultiesFromKeyword($keyword: String!) {
    facultiesFromKeyword(keyword: $keyword) {
      id
      name
      school
      employeeID
    }
  }
`;

const StyledTextField = withStyles({
  root: {
    backgroundColor: mainTheme.palette.cardbackground.main,
    marginBottom: '10px',
    borderRadius: '5px',
    '& label.Mui-focused': {
      color: '#F2F2F2',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: mainTheme.palette.cardbackground.main,
      },
      '&:hover fieldset': {
        borderColor: mainTheme.palette.cardbackground.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: mainTheme.palette.cardbackground.main,
      },
    },
  },
})(TextField);

const StyledLists = withStyles({
  root: {
    backgroundColor: mainTheme.palette.cardbackground.main,
    color: mainTheme.palette.cardbackground.title,
    marginBottom: '10px',
    borderRadius: '5px',
  },
})(ListItem);

const FacultySearchResult = ({ keyword }) => {
  const { loading, error, data } = useQuery(SEARCH_FACULTIES, {
    variables: { keyword },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <List>
      {data.facultiesFromKeyword.map(({ id, name, school, employeeID }) => (
        <StyledLists button component="a" href={`/faculty/${employeeID}`} key={id}>
          <ListItemText primary={name} secondary={school} />
        </StyledLists>
      ))}
    </List>
  );
};

const FacultySearch = () => {
  const [keyword, setKeyword] = useState('');

  return (
    <Box>
      <MetaDescriptor title="Search Faculty - VITspot" description="Search Faculty - VITspot" />
      <Layout appBarTitle="Faculty">
        <Container maxWidth="md">
          <div style={{ marginTop: '20px' }} />
          <StyledTextField
            fullWidth
            id="standard-basic"
            label="Search faculty"
            variant="outlined"
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />
          <FacultySearchResult keyword={keyword} />
        </Container>
      </Layout>
    </Box>
  );
};

export default FacultySearch;
