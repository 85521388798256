import React from 'react';

import { Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiExpandMoreIcon from '@material-ui/icons/ExpandMore';

import mainTheme from '../../themes/main.theme';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    color: 'white',
  },
});
const ExpandMoreIcon = withStyles({
  root: {
    color: 'white',
  },
})(MuiExpandMoreIcon);
const AccordionSummary = withStyles({
  root: {
    backgroundColor: mainTheme.palette.cardbackground.main,
    borderRadius: '2px',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: mainTheme.palette.cardbackground.main,
  },
}))(MuiAccordionDetails);

const componentStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3,1fr)',
    },
  },
  card: {
    marginBottom: '15px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 0,
  },
  cover: {
    width: '140px',
    height: '140px',
  },
}));

const ComponentFiles = ({ files }) => {
  const classes = componentStyles();

  return (
    <div className={classes.root}>
      {files.map((file) => (
        <div key={file.id} className={classes.card}>
          <CardMedia
            className={classes.cover}
            image={file.thumbnailUri}
            title="Question Paper"
            onClick={() => {
              window.open(file.uri);
            }}
          />
        </div>
      ))}
    </div>
  );
};

const ComponentContainer = ({ name, files }) => {
  const classes = useStyles();
  return (
    <Accordion disabled={files.length === 0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography className={classes.heading}>{name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ComponentFiles files={files} />
      </AccordionDetails>
    </Accordion>
  );
};

const ComponentAccordion = ({ components }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {components.map((courseComponent) => (
        <ComponentContainer name={courseComponent.name} files={courseComponent.files} key={courseComponent.id} />
      ))}
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const SubCoursesContainer = ({ subCourses }) => (
  <Box style={{ marginTop: '20px' }}>
    <ComponentAccordion components={subCourses} />
  </Box>
);
