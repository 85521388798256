/* eslint-disable import/prefer-default-export */
import { CircularProgress } from '@material-ui/core';

export const LoadingAnimation = () => (
  <CircularProgress
    color="secondary"
    style={{
      position: 'absolute',
      top: '100px',
      left: '48%',
    }}
  />
);
