/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import { Button, Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Layout from '../../components/layout';
import MetaDescriptor from '../../components/MetaDescriptor';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '2px',
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2px',
      width: '95%',
    },
  },
  title: {
    fontSize: 18,
    letterSpacing: '0.05rem',
    color: theme.palette.cardbackground.title,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  textfield: {
    width: '100%',
  },
  buttonSubmit: {
    color: theme.palette.cardbackground.dark,
    backgroundColor: theme.palette.cardbackground.content,
    width: '15rem',
    '&:hover': {
      color: theme.palette.cardbackground.dark,
      backgroundColor: theme.palette.cardbackground.content,
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  buttonAdmin: {
    color: theme.palette.cardbackground.content,
    backgroundColor: 'none',
    width: '15rem',
    margin: '1rem 1rem 1rem 1rem',
    textTransform: 'lowercase',
    '&:hover': {
      color: theme.palette.cardbackground.content,
      backgroundColor: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  text: {
    margin: '3rem 0',
    float: 'left',
    display: 'flex',
    color: theme.palette.cardbackground.title,
    flexDirection: 'column',
    alignItems: 'start',
  },
  developers: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '1rem 0',
    color: '#000000',
  },
  cover: {
    width: '10%',
    height: '10%',
    margin: '150px auto 10px auto',
    display: 'block',
    borderRadius: '5px',

    [theme.breakpoints.down('sm')]: {
      width: '20%',
      height: '20%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '30%',
      height: '30%',
    },
  },
}));
const About = () => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [feedBack, setFeedBack] = useState('');
  return (
    <Box>
      <MetaDescriptor title="About" description="About vitspot" />
      <Layout appBarTitle="About" />
      <Container className={classes.root}>
        <img
          className={classes.cover}
          alt="logo"
          title="logo"
          src="https://media-exp3.licdn.com/dms/image/C510BAQF8bFyJIy3Y8Q/company-logo_200_200/0/1577858110500?e=2159024400&v=beta&t=bh1LzMYC5yB9GJQWETmpRGEzr5Qc6z9idARvPK9E3hg"
        />
        <Typography variant="h6" className={classes.title}>
          Copyright © VITspot Network
        </Typography>
        <Container>
          {/* <Container className={classes.text}>
            <Typography variant="subtitle1">Feedback</Typography>
            <Typography variant="caption">Please give us feedback and help us improve</Typography>
            <br />
            <TextField
              label="Feedback"
              multiline
              rows={6}
              variant="filled"
              className={classes.textfield}
              onChange={(e) => {
                setFeedBack(e.target.value);
              }}
            />
          </Container>
          <Button className={classes.buttonSubmit}>Submit</Button>
          <Button className={classes.buttonAdmin}>admin@vitspot.com</Button> */}
          <Container style={{ marginTop: '3rem' }}>
            <Typography variant="h6" gutterBottom>
              Developed by
            </Typography>
            <Container className={classes.developers}>
              <Typography variant="subtitle1" gutterBottom>
                <Link href="https://github.com/YashKumarVerma" style={{ color: 'white' }}>
                  {' '}
                  Yash Kumar Verma
                </Link>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                <Link href="https://github.com/Deepakmukka1" style={{ color: 'white' }}>
                  {' '}
                  Mukka Deepak
                </Link>
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                <Link href="https://github.com/tanay13" style={{ color: 'white' }}>
                  {' '}
                  Tanay Raj
                </Link>
              </Typography>
            </Container>
          </Container>
        </Container>
      </Container>
    </Box>
  );
};

export default About;
