import { useState } from 'react';

import { useQuery, gql } from '@apollo/client';
import { Box, withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';

import Layout from '../../components/layout';
import MetaDescriptor from '../../components/MetaDescriptor';

const SEARCH_COURSES = gql`
  query coursesFromKeyword($keyword: String!) {
    coursesFromKeyword(keyword: $keyword) {
      id
      name
      code
    }
  }
`;
const StyledLists = withStyles({
  root: {
    backgroundColor: '#1D2226',
    color: '#F2F2F2',
    marginBottom: '10px',
    borderRadius: '5px',
  },
})(ListItem);

const StyledTextField = withStyles({
  root: {
    backgroundColor: '#1D2226',
    marginBottom: '10px',
    borderRadius: '5px',
    '& label.Mui-focused': {
      color: '#F2F2F2',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1D2226',
      },
      '&:hover fieldset': {
        borderColor: '#1D2226',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1D2226',
      },
    },
  },
})(TextField);

const CourseSearchResults = ({ keyword }) => {
  const { loading, error, data } = useQuery(SEARCH_COURSES, {
    variables: { keyword },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <List>
      {data.coursesFromKeyword.map(({ id, name, code }) => (
        <StyledLists button component="a" href={`/course/${code}`} key={id}>
          <ListItemText primary={name} />
        </StyledLists>
      ))}
    </List>
  );
};

const CourseSearch = () => {
  const [keyword, setKeyword] = useState('');

  return (
    <Box>
      <MetaDescriptor title="Search courses - VITspot" description="Search courses" />
      <Layout appBarTitle="Courses">
        <Container maxWidth="md">
          <div style={{ marginTop: '20px' }} />
          <StyledTextField fullWidth label="Search Courses" variant="outlined" onChange={(e) => setKeyword(e.target.value)} value={keyword} />
          <CourseSearchResults keyword={keyword} />
        </Container>
      </Layout>
    </Box>
  );
};

export default CourseSearch;
